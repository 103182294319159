import React, { Component } from "react";
const ComingSoon = () => {
  return (
    <>
      <div class="error-page-container">
        <div class="error-content text-center d-flex align-items-center justify-content-between">
          <div
            class="error-bg d-none d-md-block"
            style={{ backgroundImage: "url(include/images/shape-4.png)" }}
          ></div>
          <div class="error-content-box">
            <h1>Coming soon</h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
